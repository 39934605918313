import React, { useState } from "react";
import { diag } from "@opentelemetry/api";
import { useStore } from "@tanstack/react-store";
import { useQuery } from "@tanstack/react-query";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  DialogActions,
  Button as FluentButton,
  Input,
  Label,
} from "@fluentui/react-components";
import { Stack, Chip, Button, IconButton, Paper, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { ArrowSyncCircleRegular } from "@fluentui/react-icons";
import { AssetHierarchyRef, AssetSchema, AssetTypeSchema, ParameterSchema } from "@ddb/parameter-service";
import { SavedQuery, isTemplateQuery, isSavedQuery } from "../../shared/types";
import { environmentContextService, parameterService } from "../../shared/ddb";
import { CurrentQuery, STORE, saveQueries } from "../store";
import { writeValuesToCells, checkValuesInCells } from "../excel";
import { CurrentQuery as CurrentQueryComponent } from "./CurrentQuery";

export type WithPath<T> = T & { path: string };

export type AssetSchemaAndParameterSchema = [
  Array<WithPath<AssetSchema>>,
  Array<WithPath<ParameterSchema>>,
  Array<ParameterSchema["parameter_type"]>,
];

export function query(query: CurrentQuery): () => Promise<AssetSchemaAndParameterSchema> {
  return async () => {
    try {
      const envService = environmentContextService();
      const env = parameterService();
      const project = await envService.getProjectById({ projectId: query.project_id as unknown as object });
      const parameters = await env.getAllParameters({
        projectId: [query.project_id || ""],
        dataSetId: isTemplateQuery(query) ? query.data_sets_ids : undefined,
      });
      const assets = await env.getAssets({
        projectId: [query.project_id || ""],
        assetId: parameters.data?.parameters.map(({ parents }) => parents.map(({ id }) => id)).flat(),
      });
      const param_types: Array<ParameterSchema["parameter_type"]> = [];
      for (const param of parameters.data?.parameters || []) {
        if (!param_types.some((type) => type.id === param.parameter_type.id)) {
          param_types.push(param.parameter_type);
        }
      }
      const asset_hierarchys: Array<AssetHierarchyRef[]> = [];
      for (const asset of assets.data?.assets || []) {
        try {
          const hierarchy = await env.getAssetHierarchy({ assetIds: asset.id as unknown as string[] });
          asset_hierarchys.push(hierarchy.data.hierarchies.flat());
        } catch (error) {
          diag.error(error);
          continue;
        }
      }
      const assetsWithPaths = assets.data.assets.map((asset) => {
        const hierarchy = asset_hierarchys.find((h) => h[0].id === asset.id);
        const path = `${project.data?.project.short_title || ""}${hierarchy ? "/" : ""}${
          hierarchy
            ?.map((asset) => asset.name)
            .reverse()
            .join("/") || ""
        }`;
        return { ...asset, path };
      });
      const parametersWithPath = parameters.data.parameters.map((parameter) => {
        const parentAsset = assetsWithPaths.find((asset) => asset.id === parameter.parents[0]?.id);
        const path = `${parentAsset?.path}/${parameter.parameter_type.name}`;
        return { ...parameter, path };
      });

      return [assetsWithPaths, parametersWithPath, param_types];
    } catch (error) {
      diag.error(error);
      throw error;
    }
  };
}

const AssetTypeNameCell: React.FC<{ assetType?: AssetTypeSchema }> = ({ assetType }) => <>{assetType?.name || ""}</>;
const AssetNameCell: React.FC<{ asset?: AssetSchema }> = ({ asset }) => <>{asset?.name || ""}</>;
const ParametersValueAndUnitSymbolCell: React.FC<{ parameter?: ParameterSchema }> = ({ parameter }) => {
  const value = parameter?.selected_entry?.values[0].value || "";
  const unit_symbol = parameter?.selected_entry?.values[0].unit?.symbol || "";
  return (
    <>
      {value} {unit_symbol}
    </>
  );
};

const SelectParameters: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const currentQuery = useStore(STORE, ({ currentQuery }) => currentQuery);
  const parameterQuery = useQuery({
    refetchInterval: 120000, // 2 minutes
    queryKey: ["parameters", currentQuery.id || "new"],
    queryFn: query(currentQuery),
  });
  const assets = parameterQuery.data?.[0] || [];
  const params = parameterQuery.data?.[1] || [];
  const param_types = parameterQuery.data?.[2] || [];

  async function handleInsertClick(): Promise<void> {
    const checkedCells: boolean = await checkValuesInCells(assets, params);
    if (!checkedCells) {
      setErrorMessage("Some cells would be overwritten by this command");
      setIsDialogOpen(true);
    } else {
      writeValuesToCells(assets, params);
    }
  }

  async function handleSaveQueryClick(ev: React.FormEvent): Promise<void> {
    ev.preventDefault();
    const formData = new FormData(ev.target as HTMLFormElement);
    const name = formData.get("name") as string;
    if (isSavedQuery(currentQuery)) {
      const query: SavedQuery = { ...currentQuery, name };
      STORE.setState((state) => {
        const queries = state.queries.map((q) => (q.id === query.id ? query : q));
        return { ...state, queries };
      });
      await saveQueries();
    } else {
      const query: SavedQuery = {
        ...currentQuery,
        name,
        project_id: currentQuery.project_id!,
        id: crypto.randomUUID(),
      };
      STORE.setState((state) => ({
        ...state,
        queries: [...state.queries, query],
      }));
      await saveQueries();
    }
    setOpen(false);
  }

  function handleRefreshClick(): void {
    parameterQuery.refetch();
  }

  return (
    <Stack direction="column" spacing={2}>
      <CurrentQueryComponent />

      <Stack direction="row" spacing={1}>
        <Chip label={`${assets.length} assets`} />
        <Chip label={`${params.length} parameters`} />
      </Stack>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <DataGrid
          sx={{ height: 500 }}
          autosizeOptions={{ includeOutliers: true, includeHeaders: false }}
          rows={assets.map((asset) => ({
            id: asset.id,
            parent: asset,
            children: params.filter((param) => param.parents.some((parent) => parent.id === asset.id)),
          }))}
          columnGroupingModel={[
            {
              groupId: "parent",
              headerName: "Parent Asset",
              children: [{ field: "parent.asse_type.name" }, { field: "parent.name" }],
            },
            {
              groupId: "children",
              headerName: "Parameters",
              children: param_types.map(({ id }) => ({ field: `child.${id}.value` })),
            },
          ]}
          columns={[
            {
              field: "parent.asse_type.name",
              headerName: "Type",
              valueGetter: (_, row) => row.parent.asset_type,
              renderCell: (params) => <AssetTypeNameCell assetType={params.value as AssetTypeSchema} />,
            },
            {
              field: "parent.name",
              headerName: "Name",
              valueGetter: (_, row) => row.parent,
              renderCell: (params) => (
                <Tooltip title={params.value?.path}>
                  <span className="table-cell-trucate">
                    <AssetNameCell asset={params.value as AssetSchema} />
                  </span>
                </Tooltip>
              ),
            },
            ...param_types.map((param_type) => ({
              field: `child.${param_type.id}.value`,
              headerName: param_type.name,
              valueGetter: (_, row) => row.children.find(({ parameter_type }) => parameter_type.id === param_type.id),
              renderCell: (params) => (
                <Tooltip title={params.value?.path}>
                  <span className="table-cell-trucate">
                    <ParametersValueAndUnitSymbolCell parameter={params.value as ParameterSchema} />
                  </span>
                </Tooltip>
              ),
            })),
          ]}
        />
      </Paper>

      <Stack direction="row" spacing={1} justifyContent="flex-end">
        <IconButton color="primary" onClick={handleRefreshClick}>
          <ArrowSyncCircleRegular color="primary" />
        </IconButton>
        <Button onClick={handleInsertClick}>Insert Table Into Excel</Button>
        <Dialog open={isDialogOpen} modalType="alert">
          <DialogSurface>
            <DialogBody>
              <DialogTitle>Error</DialogTitle>
              <DialogContent>
                <p>{errorMessage}</p>
              </DialogContent>
              <DialogActions>
                <DialogTrigger disableButtonEnhancement>
                  <FluentButton color="secondary" onClick={() => setIsDialogOpen(false)}>
                    Close
                  </FluentButton>
                </DialogTrigger>
              </DialogActions>
            </DialogBody>
          </DialogSurface>
        </Dialog>
        <Dialog open={open} onOpenChange={(_, data) => setOpen(data.open)}>
          <DialogTrigger disableButtonEnhancement>
            <Button>{isSavedQuery(currentQuery) ? "Update Query" : "Save Query"}</Button>
          </DialogTrigger>
          <DialogSurface aria-describedby={undefined}>
            <form onSubmit={handleSaveQueryClick}>
              <DialogBody>
                <DialogTitle>{isSavedQuery(currentQuery) ? "Update Query" : "Save Query"}</DialogTitle>
                <DialogContent>
                  <Stack direction="column" spacing={1}>
                    <Label required htmlFor={"name-input"}>
                      Query Name
                    </Label>
                    <Input required id={"name-input"} name={"name"} />
                  </Stack>
                </DialogContent>
                <DialogActions>
                  <DialogTrigger disableButtonEnhancement>
                    <FluentButton color="secondary">Close</FluentButton>
                  </DialogTrigger>
                  <FluentButton type="submit" color="primary">
                    {isSavedQuery(currentQuery) ? "Update Query" : "Save Query"}
                  </FluentButton>
                </DialogActions>
              </DialogBody>
            </form>
          </DialogSurface>
        </Dialog>
      </Stack>
    </Stack>
  );
};

export default SelectParameters;
