/* global Excel */
import { EXCEL_FUNCTION, createFunction } from "./functions";
import {
  fillColor,
  setCellStyle,
  continuousThinBorder,
  noBorder,
  continuousMediumBorder,
} from "./styles/conditional_formatting";

function uniqParameterTypes(parameters: any[]): {
  uniqParameterTypesIds: Set<string>;
  parameterTypeParentsLookup: { [key: string]: Set<string> };
  parameterTypeNameFunctions: string[];
} {
  const uniqParameterTypesIds = new Set<string>();
  const parameterTypeParentsLookup = {};
  parameters.map((parameter) => {
    const {
      parameter_type: { id: parameterTypeId },
      parents,
    }: { parameter_type: { id: string }; parents: any[] } = parameter;
    uniqParameterTypesIds.add(parameterTypeId);
    parents.map(({ id: parentAssetId }) => {
      if (!parameterTypeParentsLookup[parentAssetId]) {
        parameterTypeParentsLookup[parentAssetId] = new Set([]);
      }
      parameterTypeParentsLookup[parentAssetId].add(parameterTypeId);
    });
  });
  const parameterTypeNameFunctions = [...uniqParameterTypesIds].map((id) =>
    createFunction(EXCEL_FUNCTION.PARAMETER_TYPE.NAME, id)
  );
  return { uniqParameterTypesIds, parameterTypeParentsLookup, parameterTypeNameFunctions };
}

function assetFormulas(assets: { id: string }[]): string[][] {
  return assets.map(({ id }) => [
    createFunction(EXCEL_FUNCTION.ASSET.ASSET_TYPE_NAME, id),
    createFunction(EXCEL_FUNCTION.ASSET.NAME, id),
    createFunction(EXCEL_FUNCTION.ASSET.PATH, id),
  ]);
}

export async function checkValuesInCells(assets, parameters): Promise<boolean> {
  return Excel.run(async (context) => {
    const { parameterTypeNameFunctions } = uniqParameterTypes(parameters);
    const numberOfRows: number = assetFormulas(assets).length + 3 - 1; // the number of formulas + 3 rows for headers and - 1 due to list length in ts/js
    const numberOfColumns: number = parameterTypeNameFunctions.length * 2 + 3 - 1; // the number of formulas * 2 since each parameter has two columns + 3 rows for asset names and - 1 due to list length in ts/js
    const activeCell = context.workbook.getActiveCell();
    const range = activeCell.getResizedRange(numberOfRows, numberOfColumns);
    range.load("values");
    await context.sync();

    const isEmpty = range.values.every((row: any[]) => row.every((cell: any) => !cell || cell === null || cell === ""));
    return isEmpty;
  });
}

export function writeValuesToCells(assets, parameters) {
  const secondaryHeaders = ["Asset Type", "Asset Name", "Path"];
  const numberOfAssetsColumns = secondaryHeaders.length;

  const assetFunctions = assetFormulas(assets);

  const { uniqParameterTypesIds, parameterTypeParentsLookup, parameterTypeNameFunctions } =
    uniqParameterTypes(parameters);

  const numberOfParametersColumns = parameterTypeNameFunctions.length;

  return Excel.run(async (context) => {
    const activeCell = context.workbook.getActiveCell();

    const assetsRange = activeCell.getResizedRange(0, numberOfAssetsColumns - 1);
    assetsRange.values = [Array(numberOfAssetsColumns).fill("DDB Assets", 0)];
    assetsRange.setCellProperties([
      Array(numberOfAssetsColumns).fill(setCellStyle(fillColor.darkBlue, true, false, "White")),
    ]);
    assetsRange.merge();

    secondaryHeaders.forEach((header, index) => {
      const assetsHeadersRange = activeCell.getOffsetRange(1, index).getResizedRange(1, 0);
      assetsHeadersRange.values = [[""], [header]];
      assetsHeadersRange.setCellProperties([
        [
          setCellStyle(
            fillColor.mediumBlue,
            true,
            false,
            "Black",
            Excel.HorizontalAlignment.center,
            Excel.VerticalAlignment.center,
            {
              top: continuousMediumBorder,
              right: continuousMediumBorder,
              bottom: noBorder,
              left: continuousMediumBorder,
            }
          ),
        ],
        [
          setCellStyle(
            fillColor.mediumBlue,
            true,
            false,
            "Black",
            Excel.HorizontalAlignment.center,
            Excel.VerticalAlignment.center,
            {
              top: noBorder,
              right: continuousMediumBorder,
              bottom: continuousMediumBorder,
              left: continuousMediumBorder,
            }
          ),
        ],
      ]);
    });
    const assetsValuesRange = activeCell
      .getOffsetRange(3, 0)
      .getResizedRange(assetFunctions.length - 1, numberOfAssetsColumns - 1);
    assetsValuesRange.setCellProperties(
      Array(assetFunctions.length).fill(
        Array(numberOfAssetsColumns).fill(
          setCellStyle(
            fillColor.lightBlue,
            false,
            false,
            "Black",
            Excel.HorizontalAlignment.left,
            Excel.VerticalAlignment.center,
            {
              top: continuousThinBorder,
              right: continuousThinBorder,
              bottom: continuousThinBorder,
              left: continuousThinBorder,
            }
          )
        )
      )
    );
    assetsValuesRange.values = assetFunctions;

    const parametersRange = activeCell
      .getOffsetRange(0, numberOfAssetsColumns)
      .getResizedRange(0, numberOfParametersColumns * 2 - 1);
    parametersRange.values = [Array(numberOfParametersColumns * 2).fill("DDB Parameters", 0)];
    parametersRange.setCellProperties([
      Array(numberOfParametersColumns * 2).fill(setCellStyle(fillColor.darkGreen, true, false, "White")),
    ]);
    parametersRange.merge();

    let column = numberOfAssetsColumns;
    parameterTypeNameFunctions.map((typeFunction) => {
      const parametersHeadersRange = activeCell.getOffsetRange(1, column).getResizedRange(0, 1);
      parametersHeadersRange.setCellProperties([Array(2).fill(setCellStyle(fillColor.mediumGreen, true))]);
      parametersHeadersRange.values = [Array(2).fill(typeFunction, 0)];
      parametersHeadersRange.merge();
      const valueAndUnitRange = activeCell.getOffsetRange(2, column).getResizedRange(0, 1);
      valueAndUnitRange.setCellProperties([Array(2).fill(setCellStyle(fillColor.mediumGreen, false, true))]);
      valueAndUnitRange.values = [["Value", "Unit"]];
      column += 2;
    });

    const valuesAndUnitsRange = activeCell
      .getOffsetRange(3, numberOfAssetsColumns)
      .getResizedRange(assetFunctions.length - 1, numberOfParametersColumns * 2 - 1);
    const valuesAndUnits: string[][] = [];
    let assetRow: number = 0;
    assets.forEach(({ id: assetId }) => {
      let parameterColumn: number = 0;
      const assetParameters: string[] = [];
      uniqParameterTypesIds.forEach((parameterTypeId) => {
        assetParameters.push(
          createFunction(EXCEL_FUNCTION.PARAMETER.ASSET_PARAMETER_VALUE, assetId, parameterTypeId),
          createFunction(EXCEL_FUNCTION.PARAMETER.ASSET_PARAMETER_UNIT_SYMBOL, assetId, parameterTypeId)
        );
        const cellRange = activeCell
          .getOffsetRange(3 + assetRow, numberOfAssetsColumns + parameterColumn)
          .getResizedRange(0, 1);
        cellRange.setCellProperties([
          Array(2).fill(
            setCellStyle(
              parameterTypeParentsLookup[assetId].has(parameterTypeId)
                ? fillColor.highlightGreen
                : fillColor.lightGreen,
              false,
              false,
              "Black",
              Excel.HorizontalAlignment.center,
              Excel.VerticalAlignment.center,
              {
                top: continuousThinBorder,
                right: continuousThinBorder,
                bottom: continuousThinBorder,
                left: continuousThinBorder,
              }
            )
          ),
        ]);
        parameterColumn += 2;
      });
      valuesAndUnits.push(assetParameters);
      assetRow += 1;
    });
    valuesAndUnitsRange.values = valuesAndUnits;

    // TODO: autofit will be addressed at a later release
    // range.format.autofitColumns();

    return context.sync();
  });
}
