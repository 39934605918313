import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { createLightTheme } from "@fluentui/react-components";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { purple, grey, red } from "@mui/material/colors";

import { ENVIRONMENT, Environment } from "../../shared/config";

function envColor(): string {
  switch (ENVIRONMENT) {
    case Environment.Local:
      return purple[800];
    case Environment.Development:
      return purple[800];
    case Environment.UT:
      return "#673f00";
    case Environment.Production:
      return "#D44215";
    default:
      return "#D44215";
  }
}

function shade(color: string, shade: number = 100): string {
  const hex = color.replace("#", "");
  const num = parseInt(hex, 16);
  const r = (num >> 16) + shade;
  const g = ((num >> 8) & 255) + shade;
  const b = (num & 255) + shade;
  const newHex = [r, g, b];
  return `#${newHex.map((c) => Math.min(Math.max(0, c), 255).toString(16)).join("")}`;
}

const ENV_COLOR = envColor();

export const MUI_THEME = responsiveFontSizes(
  createTheme({
    components: {
      MuiButton: { defaultProps: { size: "small" } },
      MuiFilledInput: { defaultProps: { margin: "dense" } },
      MuiFormControl: { defaultProps: { margin: "dense" } },
      MuiFormHelperText: { defaultProps: { margin: "dense" } },
      MuiIconButton: { defaultProps: { size: "small" } },
      MuiInputBase: { defaultProps: { margin: "dense" } },
      MuiInputLabel: { defaultProps: { margin: "dense" } },
      MuiListItem: { defaultProps: { dense: true } },
      MuiOutlinedInput: { defaultProps: { margin: "dense" } },
      MuiFab: { defaultProps: { size: "small" } },
      MuiTable: { defaultProps: { size: "small" } },
      MuiTextField: { defaultProps: { variant: "standard", margin: "dense" } },
      MuiToolbar: { defaultProps: { variant: "dense" } },
    },
    spacing: 2,
    typography: { fontSize: 12 },
    palette: {
      mode: "light",
      primary: { main: ENV_COLOR },
      secondary: { main: grey[400], dark: grey[800] },
      action: { disabled: grey[400] },
      error: { main: red[700] },
    },
  })
);

export const FLUENT_THEME = createLightTheme({
  10: shade(ENV_COLOR, 10),
  20: shade(ENV_COLOR, 20),
  30: shade(ENV_COLOR, 30),
  40: shade(ENV_COLOR, 40),
  50: shade(ENV_COLOR, 50),
  60: shade(ENV_COLOR, 60),
  70: shade(ENV_COLOR, 70),
  80: shade(ENV_COLOR, 80),
  90: shade(ENV_COLOR, 90),
  100: shade(ENV_COLOR, 100),
  110: shade(ENV_COLOR, 110),
  120: shade(ENV_COLOR, 120),
  130: shade(ENV_COLOR, 130),
  140: shade(ENV_COLOR, 140),
  150: shade(ENV_COLOR, 150),
  160: shade(ENV_COLOR, 160),
});
